import * as React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "../components/layout"

interface SEOProps {
  description?: string
  meta?: any[]
  image?: string
  title: string
}

const SEO: React.FC<SEOProps> = ({
  description,
  meta,
  title,
  image,
}: SEOProps) => {
  const { locale }: any = React.useContext(LocaleContext)

  const {
    title: defaultTitle,
    description: defaultDescription,
    defaultLang,
    siteName,
    siteUrl,
    image: defaultImage,
    author,
  } = useStaticQuery(query).allMetaDataJson.nodes[0].data

  // We will first try to use the data passed to this component. If they are not provided, we will
  // fall back to the default data set in the site-metadata.json
  const customTitle = title || defaultTitle
  const customDescription = description || defaultDescription
  const siteOrigin =
    typeof window !== "undefined" ? window.location.origin : siteUrl
  const customLang = locale || defaultLang

  // Get the image for each page or use the default. Images are stored under /static/images/metadata_images
  const customImage = image || defaultImage
  const imageUrl = require(`../../static/images/metadata_images/${customImage}`)

  // Some small image will be generated as base64 encoded URI. So if the image is base64 format, use it
  // as is for imageURL, no need to prepend the siteUrl.
  const customImageUrl =
    imageUrl.indexOf("data:image") === 0 ? imageUrl : `${siteOrigin}${imageUrl}`

  return (
    <Helmet
      htmlAttributes={{
        customLang,
      }}
      title={customTitle}
      titleTemplate={`%s | ${siteName}`}
      meta={[
        {
          name: "description",
          content: customDescription,
        },
        {
          property: "og:title",
          content: customTitle,
        },
        {
          property: "og:description",
          content: customDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: siteName,
        },
        {
          property: "og:url",
          content: siteUrl,
        },
        {
          property: "og:image",
          content: customImageUrl,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: author,
        },
        {
          name: "twitter:title",
          content: customTitle,
        },
        {
          name: "twitter:description",
          content: customDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  description: "",
}

export default SEO

const query = graphql`
  query SiteMetaDataQuery {
    allMetaDataJson {
      nodes {
        data {
          title
          description
          defaultLang
          siteName
          siteUrl
          image
          author
        }
      }
    }
  }
`
